<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->
<template>
  <loader v-if="loading" />
  <v-container v-else fluid class="white h-100 pa-0" :class="$vuetify.breakpoint.mdAndDown ? 'bg-mobile' : ''">
    <v-row class="fill-height" no-gutters justify="center" align="center">
      <v-col class="d-none d-lg-flex fill-height flex-column justify-center" cols="12" lg="6">
        <v-img :src="backGroundImage" />
      </v-col>
      <v-col cols="12" lg="6">
        <v-card class="mx-auto" color="transparent" flat min-width="320" width="400">
          <v-col cols="12">
            <v-img :src="require('@/assets/img/maPread_logo_color.svg')" max-width="250px" width="350"
                   class="mb-3 mx-auto" max-height="70px" contain
            />
          </v-col>
          <v-col cols="12">
            <v-alert class="text-caption font-alt mb-0" color="secondary" dark dense>
              <font-awesome-icon :icon="['fal','times']" class="white--text mr-2" size="1x" />
              Le lien n'est pas valide
            </v-alert>
          </v-col>

          <v-card-actions class="d-flex justify-end">
            <v-btn small type="button" :to="{name: 'login'}">Retour à la page de connexion</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Loader from '@/modules/core/components/Loader'
  import { changeEmailConfirmation } from '@/modules/auth/api'
  import logger from '@/services/logger'
  import {mapState} from 'vuex'

  export default {
    name: 'ChangeEmail',
    components: {Loader},
    props: {
      userId: String,
      email: String,
      token: String
    },
    data() {
      return {
        invalidToken: false,
        loading: true
      }
    },
    computed: {
      ...mapState('layout', ['customDomain']),
      backGroundImage() {
        return this.customDomain !== '' ? '/img/assets/'+this.customDomain+'/login-default-bg.jpg' : require('@/assets/img/login-default-bg.png')
      }
    },
    mounted() {
      this.changeEmail()
    },
    methods: {
      async changeEmail() {
        try {
          await changeEmailConfirmation(this.userId, this.token)
          this.$root.$emit('snackbar:open', {
            snackbar: () => import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: 'L\'adresse e-mail a été modifié avec succès'
          })
          this.$auth.resetSession()
          await this.$router.push({name: 'login'})
        } catch (error) {
          if (error.response.status === 401) {
            this.loading = false
          } else {
            this.$root.$emit('snackbar:open', {
              snackbar: () => import('@/modules/core/components/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue'
            })
            logger.error(error)
            await this.$router.push({name: 'login'})
          }
        }
      }
    }
  }
</script>

